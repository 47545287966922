import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CpArea, CpTooltip, CpDropdown } from 'canopy-styleguide!sofe';
import StatusDropdown from './status-dropdown.component.js';
import { getStatusDotStyles } from './status-input.helper.js';
import styles from './status-dropdown.styles.css';

// This component, and what it uses, are being exported
// and are shared to other projects
StatusInput.propTypes = {
  statuses: PropTypes.array,
  selectedStatus: PropTypes.string,
  updateTaskStatus: PropTypes.func,
  updateStatuses: PropTypes.func,
};
export default function StatusInput({
  statuses,
  selectedStatus,
  condenseContainer,
  alignDropdownRight,
  statusNameMaxWidth,
  userId,
  updateTaskStatus,
  updateStatuses,
  width,
  disabled,
}) {
  const chosenStatus = useMemo(
    () => statuses.find(status => status.id === selectedStatus) || { name: 'Loading statuses', color: '#FFF' },
    [statuses, selectedStatus]
  );

  return (
    <div className={`${styles.statusContainer} cps-body-sm`}>
      <CpDropdown
        allowContentClicks
        disabled={disabled}
        renderTrigger={({ toggle }) => (
          <CpArea style={{ borderRadius: '8px' }} disabled={disabled}>
            <CpTooltip text={chosenStatus.name}>
              <div className={styles.selectStatus} onClick={toggle}>
                <span
                  className={styles.statusDot}
                  style={getStatusDotStyles(chosenStatus.color, chosenStatus.name === 'No status', disabled)}
                ></span>
                <div
                  className={`${condenseContainer ? 'cps-hidden' : ''} cp-ellipsis ${
                    disabled ? 'cp-color-app-disabled-text' : ''
                  }`}
                >
                  {chosenStatus.name}
                </div>
              </div>
            </CpTooltip>
          </CpArea>
        )}
        renderContent={({ close, isOpen }) => (
          <StatusDropdown
            userId={userId}
            statuses={statuses}
            close={close}
            updateTaskStatus={updateTaskStatus}
            updateStatuses={updateStatuses}
            selectedStatus={chosenStatus}
            alignDropdownRight={alignDropdownRight}
            statusNameMaxWidth={statusNameMaxWidth}
            width={width}
            isOpen={isOpen}
          />
        )}
      />
    </div>
  );
}
