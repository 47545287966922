import { fetchAsObservable } from 'fetcher!sofe';
import { sortBy, uniqBy } from 'lodash';
import { pluck, map } from 'rxjs/operators';

function preprocessTemplates(inboundTemplates) {
  const templates = uniqBy(inboundTemplates, item => item.id).sort(a => {
    if (a.is_global) {
      return 1;
    } else if (!a.is_global) {
      return -1;
    } else {
      return 0;
    }
  });
  const globalsWithoutOverrides = uniqBy(templates, item => item.name).filter(item => !!item.is_global);

  const localTemplates = templates.filter(item => !item.is_global);

  const merged = [...globalsWithoutOverrides, ...localTemplates];

  return sortBy(merged, template => template.name);
}

export function getNoticeTypes(search, hideOverridden = false) {
  let queryParams = '?service_type=notice&canopy_templates=true';
  if (search !== '' && search !== undefined) {
    queryParams = `${queryParams}&template_task_name_filter=${encodeURIComponent(search)}`;
  }

  if (!hideOverridden) {
    return fetchAsObservable(`api/template-tasks${queryParams}`).pipe(pluck('template_tasks'));
  } else {
    return fetchAsObservable(`api/template-tasks${queryParams}`).pipe(
      pluck('template_tasks'),
      map(results => {
        return preprocessTemplates(results);
      })
    );
  }
}

export function createNotice(noticePayload, clientId) {
  return fetchAsObservable(`/api/clients/${clientId}/notices`, {
    method: 'POST',
    body: noticePayload,
  }).pipe(pluck('notices'));
}

export function createClient(clientPayload) {
  return fetchAsObservable('/api/clients', {
    method: 'POST',
    body: clientPayload,
  }).pipe(pluck('clients'));
}

export const createContact = client => {
  return fetchAsObservable('/api/v2/contacts', {
    method: 'POST',
    body: client,
  });
};

export function getNoticetemplate(templateId) {
  return fetchAsObservable(`/api/template-tasks/${templateId}?include=files,document_templates,forms`).pipe(
    pluck('template_tasks')
  );
}

// TN Free Trial
export function getSuccessfulTranscriptsCount() {
  return fetchAsObservable(`/api/transcripts/trial-count`);
}

export function getNoticesCount() {
  return fetchAsObservable(`/api/notices/trial-count`);
}
