//@flow
import { pluck } from 'rxjs/operators';
import { fetchAsObservable } from 'fetcher!sofe';

export function getRelatedFiles(type: string, ids: Array<string>) {
  return fetchAsObservable(`/api/docs/related-files`, {
    method: 'POST',
    body: { related_id_mappings: { [type]: ids } },
  }).pipe(pluck('related_files_for', type));
}
