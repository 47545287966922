import { of, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { assign, cloneDeep, findIndex, isUndefined, uniq, union, uniqBy, map } from 'lodash';

import { createEditTaskModal } from 'src/create-edit-task/create-edit-task.wrapper';
import { getTask } from 'src/resources/tasks.resource.js';
import { getRelatedFiles } from 'src/resources/files.resource.js';
import StatusTypes from '../constants/status.constants';
import ServiceTypes from '../constants/service-type.constants';
import { infoToast } from 'toast-service!sofe';
import { addAnalyticsObjectToService } from 'src/common/analytics.helpers';

export const DASHBOARD_PREFERENCES_KEY = 'tasks_dashboard';
export const INCLUDE_COMPLETED_TASKS = 'include_completed_tasks';
import TaskMode from 'src/constants/task-mode.constants';
import { fullOptions, calculateDateInterval } from './task-date-filter.constants';
import { clearFilters } from './header/task-filter-groups/task-filter-groups.helper';
import { cols } from 'src/dashboard/columns.helpers';

// Returns a new array with all the rows specified by taskId updated with newValues
export function updateTaskAttributes(tasks, taskIds, newValues) {
  // subtask menu is the only place this still used
  const newTasks = cloneDeep(tasks);
  let updatedTaskIds = [];
  for (var i = 0; i < taskIds.length; i++) {
    const taskId = taskIds[i];
    const rowsToUpdate = newTasks.filter(row => row.task.id === taskId);
    for (var j = 0; j < rowsToUpdate.length; j++) {
      let row = rowsToUpdate[j];

      if (row.task.type === 'Subtask' && newValues.status_id === StatusTypes.COMPLETED) {
        const parentTaskRows = newTasks.reduce((acc, myRow, i) => {
          if (myRow.task.id === row.task.parent_task_id) acc.push({ parentTaskRow: myRow, parentIdx: i });
          return acc;
        }, []);
        parentTaskRows.forEach(({ parentTaskRow, parentIdx }) => {
          if (
            parentTaskRow &&
            parentTaskRow.task.status_id !== StatusTypes.COMPLETED &&
            parentTaskRow.task.subtask_total === 1 + parentTaskRow.task.subtask_completed_sum
          ) {
            updatedTaskIds.push(parentTaskRow.task.id);
            newTasks[parentIdx].task = { ...parentTaskRow.task, ...newValues };
          } else if (
            parentTaskRow &&
            parentTaskRow.task.status_id !== StatusTypes.COMPLETED &&
            parentTaskRow.task.subtask_total > 1 + parentTaskRow.task.subtask_completed_sum
          ) {
            newTasks[parentIdx].task.subtask_completed_sum += 1;
          }
        });
      }

      row.task = { ...row.task, ...newValues };
      updatedTaskIds.push(row.task.id);
    }
  }
  return [newTasks, uniq(updatedTaskIds)];
}

export function markParentTaskComplete(task, newValues, shouldCalculateCompletedSubtasks = true) {
  let updatedTaskId = false;
  if (shouldCalculateCompletedSubtasks) {
    task.task.subtask_completed_sum = task.task.subtasks?.reduce((acc, subtask) => {
      if (subtask.status_id === StatusTypes.COMPLETED) {
        return ++acc;
      }
      return acc;
    }, 0);
  }
  if (task.task.subtask_completed_sum === task.task.subtask_total && task.task.status_id !== StatusTypes.COMPLETED) {
    task.task = { ...task.task, ...newValues };
    updatedTaskId = true;
  }
  return { updatedTask: task, updatedTaskId };
}

export function findParentTasks(tasksList, subtasksMarkedAsComplete, newValues) {
  let ids = [];
  let updatedTasksList = tasksList.map(task => {
    subtasksMarkedAsComplete.map(subtask => {
      if (subtask.task.parent_task_id === task.task.id) {
        const { updatedTask, updatedTaskId } = markParentTaskComplete(
          { ...task, task: { ...task.task, subtask_completed_sum: task.task.subtask_completed_sum + 1 } },
          newValues,
          false
        );
        task = updatedTask;
        updatedTaskId && ids.push(task.task.id);
      }
    });
    return task;
  });
  return { updatedTasks: updatedTasksList, updatedTaskIds: ids };
}

export function optimisticallyUpdateTasksAttributes(tasks, taskIds, newValues) {
  let returnedUpdatedTaskIds = [...taskIds];
  let subtasksMarkedAsComplete = [];
  let updatedNewTasks = tasks.map(task => {
    if (taskIds.includes(task.task.id)) {
      if (
        task.task.type === 'Subtask' &&
        newValues.status_id === StatusTypes.COMPLETED &&
        task.task.status_id !== StatusTypes.COMPLETED
      ) {
        task.task = { ...task.task, ...newValues };
        subtasksMarkedAsComplete.push(task);
      } else {
        task.task = { ...task.task, ...newValues };
      }
    }
    if (task.task.subtasks) {
      let flag = false;
      task.task.subtasks = task.task.subtasks.map(subtask => {
        if (taskIds.includes(subtask.id)) {
          if (newValues.status_id === StatusTypes.COMPLETED) {
            flag = true;
          }
          return { ...subtask, ...newValues };
        }
        return subtask;
      });
      if (flag) {
        let { updatedTask, updatedTaskId } = markParentTaskComplete(task, newValues);
        task = updatedTask;
        updatedTaskId && returnedUpdatedTaskIds.push(task.task.id);
      }
    }
    return task;
  });
  if (subtasksMarkedAsComplete.length) {
    let { updatedTasks, updatedTaskIds } = findParentTasks(
      updatedNewTasks,
      uniqBy(subtasksMarkedAsComplete, 'task.id'),
      newValues
    );
    returnedUpdatedTaskIds = [...returnedUpdatedTaskIds, ...updatedTaskIds];
    updatedNewTasks = updatedTasks;
  }
  return [updatedNewTasks, returnedUpdatedTaskIds];
}

// Returns a new array with the single row specified by dateId updated with newValues
export function updateDateAttributesInMemory(tasks, dateIds, newValues, parentTaskId, dateTypeId) {
  const newTasks = cloneDeep(tasks);
  for (var i = 0; i < dateIds.length; i++) {
    const taskId = dateIds[i];
    let rowToUpdate;
    if (parentTaskId) {
      let parentTaskIndex = findIndex(newTasks, row => row.task.id === parentTaskId);
      if (parentTaskIndex >= 0) {
        let subtaskIndex = findIndex(newTasks[parentTaskIndex].task.subtasks, subtask => {
          return subtask.dates && subtask.id === taskId;
        });
        if (subtaskIndex >= 0) {
          let dates = newTasks[parentTaskIndex].task.subtasks[subtaskIndex].dates;
          if (Array.isArray(dates)) {
            dates.forEach(date => {
              if (date.date_type_id === dateTypeId) {
                date.date === newValues.calculated_date;
              }
              return date;
            });
          }
        }
      }
    } else {
      rowToUpdate = newTasks.find(row => row.task.id === taskId);
    }
    if (rowToUpdate) {
      let dateToChangeIndex = rowToUpdate.task.dates.findIndex(d => d.date_type_id === dateTypeId);
      rowToUpdate.task.dates[dateToChangeIndex] = assign(rowToUpdate.task.dates[dateToChangeIndex], newValues);
    }
  }
  return newTasks;
}

// Returns a new array of columns with the specified field reordered to the specified index
export function reorderColumn(columns, field, index) {
  let newColumns = columns.slice();
  const oldIndex = findIndex(newColumns, item => item === field);

  newColumns[oldIndex] = null;
  newColumns.splice(index, 0, field);
  newColumns = uniq(newColumns.filter(item => !!item));

  return newColumns;
}

//AFTER REMOVAL OF SERVICE_WORKSPACE TOGGLE, THIS IS USED ONLY FOR ESIGN, CONSIDER REFACTOR
//returns an observable that will open the edit task modal
//obs.subscribe() must be called to open the modal
//the observable should be canceled when the parent component is unmounted (to close the modal)
export function editTask(id, statuses, autoUpdate = false) {
  return getTask(id).pipe(
    switchMap(task => {
      if (isUndefined(task)) {
        window.dispatchEvent(new CustomEvent('taskunavailable'));
        return of();
      } else {
        return createEditTaskModal(task, null, null, statuses, autoUpdate);
      }
    })
  );
}

export async function buildCopiedTask(id) {
  let task = await getTask(id).toPromise();
  let CRSubtasks = task.subtasks.filter(
    subtask => subtask.request_tools.length && subtask.request_tools[0].tool_type === 'simple'
  );
  if (task) {
    let files = {};
    if (CRSubtasks.length) {
      files = await getRelatedFiles(
        'request_tool',
        CRSubtasks.map(subtask => subtask.request_tools[0].id)
      ).toPromise();
    }
    task.name = `(copy) ${task.name}`;
    if (task.service_type === ServiceTypes.NOTICE) {
      task.template_id = task.ancestor_id;
    }
    task.isCopy = true;
    const analyticsObj = {
      ancestor_type: 'copy',
      ancestor_id: id,
    };
    task = addAnalyticsObjectToService(analyticsObj, task);
    // place request_tool files onto request_tool
    task.subtasks.forEach(subtask => {
      if (subtask.request_tools.length && files[subtask.request_tools[0].id]) {
        subtask.request_tools[0].relationships.files = files[subtask.request_tools[0].id];
      }
      //remove comment files
      subtask.relationships.files = subtask.relationships.files.filter(file => !file.for_comment);
    });
    // Make sure ids are reset for the copied task so relative dates can be adjusted to rely on new task
    resetIds(task);
    return { service: task, mode: TaskMode.COPY };
  } else {
    window.dispatchEvent(new CustomEvent('taskunavailable'));
  }
}

export function resetIds(task) {
  task.dates.forEach(date => {
    if (date.relative) {
      date.relative.base_task_id = '_' + date.relative.base_task_id;
    }
  });
  task.id = '_' + task.id;

  task.subtasks.forEach(sub => {
    sub.id = '_' + sub.id;
    sub.dates.forEach(date => {
      if (date.relative) {
        if (date.relative.base_task_id === task.id) {
          date.relative.base_task_id = 'PARENT';
        } else {
          date.relative.base_task_id = `_${date.relative.base_task_id}`;
        }
      }
    });
    sub.request_tools.forEach(requestTool => {
      requestTool.id = undefined;
    });
  });
}

export function getWorkspaceRoute(task) {
  if (task.service_type === ServiceTypes.TAX_RETURN) {
    return `/tax-prep-tasks/${task.id}/prepare`;
  }
  const isSubtask = !!task.parent_task_id;
  const parentTaskId = isSubtask ? task.parent_task_id : task.id;
  let route = task.client_id ? `/task/${parentTaskId}/client/${task.client_id}` : `/task/${parentTaskId}`;
  if (isSubtask) {
    route = `${route}?subtask=${task.id}`;
  }
  return route;
}

// Transform task data to the fields we use on the dashboard for the task
export function toDashboardTaskFields(newTaskData) {
  return {
    ...newTaskData,
    subtask_total: newTaskData && newTaskData.subtasks ? newTaskData.subtasks.length : 0,
  };
}

export function refetchRowsById(IDs, tasks, refreshFunction) {
  if (IDs.length > 10) {
    if (refreshFunction) {
      infoToast(
        'Your task list is out of date. Refresh now to see updates. Refresh now?',
        'Refresh data',
        refreshFunction,
        15000
      );
    } else {
      infoToast('Your task list is out of date. Refresh now to see updates.');
    }
    return of();
  } else {
    return forkJoin(IDs.map(id => getTask(id)));
  }
}

export function updateRowsFromTickle(updatedRows, rowsToUpdate) {
  return cloneDeep(rowsToUpdate).map(row => {
    const newRow = updatedRows.find(item => item.id === row.task.id);
    if (newRow) {
      // if old row has date, try and find a match in new row from socket
      row.task = { ...row.task, ...toDashboardTaskFields(newRow) };
    }
    return row;
  });
}

export function recalculateIntervals(calculatedDate) {
  let newDateObj;
  if (calculatedDate.date_group && calculatedDate.filter_params) {
    newDateObj = {
      order: calculatedDate.order,
      filter_params: fullOptions.includes(calculatedDate.date_group)
        ? [calculateDateInterval(calculatedDate.date_group)]
        : calculatedDate.filter_params,
      date_group: calculatedDate.date_group,
      date_type_id: calculatedDate.date_type_id,
    };
  } else {
    newDateObj = {
      order: calculatedDate.order,
      filter_params: calculatedDate.filter_params,
      date_type_id: calculatedDate.date_type_id,
    };
  }
  return newDateObj;
}

// This function is used to add filters to multiple or one column. The filters prop should be an object: {column_name: {order: , filter_params: }, ...as many columns as you want to filter} and will update those columnSettings as well as update the visibleColumns to include all columns provided
// Whatever filters are not supplied in the filters object will be reset
export function buildNewFilterObj(filters, preferences) {
  let keys = Object.keys(filters);
  let visibleColumnsList = [...keys];
  let index = visibleColumnsList.indexOf('date.calculated_date');
  if (index >= 0) {
    visibleColumnsList.splice(index, 1);
    filters['date.calculated_date'].map(type => {
      visibleColumnsList.push(`task.dates.${type.date_type_id}.calculated_date`);
    });
  }
  let visibleColumns;
  if (preferences.visibleColumns) {
    visibleColumns = union(preferences.visibleColumns, visibleColumnsList);
  } else {
    visibleColumns = preferences.columnSettings
      ? union(Object.keys(preferences.columnSettings), visibleColumnsList)
      : visibleColumnsList;
  }
  let columnSettings = preferences.columnSettings
    ? clearFilters(Object.keys(preferences.columnSettings), preferences.columnSettings['date.calculated_date'])
    : {}; // If there aren't any filters on the columnSettings, we don't need to clear them (especially because the new ones are being explicitly set below)
  keys.map(key => {
    columnSettings[key] = filters[key];
  });
  return { visibleColumns, columnSettings };
}

export function hasParentWithClass(el, theClass) {
  if (typeof el.className === 'string' && el.classList.contains(theClass)) return true;
  return el.parentNode && hasParentWithClass(el.parentNode, theClass);
}

export function clearFiltersIfNotVisible(originalColumnSettings = {}, originalVisibleColumns = [], columnDefs) {
  let columnSettings = {};
  let changed = false;
  let visibleColumns = originalVisibleColumns.filter(col => {
    return !columnDefs[col]?.hidden; // check if it's hidden from the user with their permissions
  });
  if (visibleColumns.length !== originalVisibleColumns.length) changed = true;
  map(originalColumnSettings, (settings, colName) => {
    if (colName === 'date.calculated_date') {
      // is the date columns' settings
      if (Array.isArray(originalColumnSettings[colName])) {
        columnSettings[colName] = settings.map(dateType => {
          const columnKey = Object.keys(columnDefs).find(col =>
            col?.toLowerCase().includes(dateType?.date_type_id?.toLowerCase())
          );
          const column = columnDefs[columnKey] || {};
          if (
            !visibleColumns.includes(column.field) &&
            (dateType.date_group || dateType.order || dateType.filter_params)
          ) {
            // not included in the visible columns and has a filter applied
            changed = true;
            return {
              date_group: null,
              date_type_id: dateType.date_type_id,
              filter_params: null,
              order: null,
            };
          }
          return dateType;
        });
      } else {
        columnSettings[colName] = [];
        changed = true;
      }
    } else if (
      !visibleColumns.includes(colName) &&
      !['task.name', 'task.status_name'].includes(colName) &&
      (settings.filter_params || settings.order)
    ) {
      // not a date column, not included in the visibleColumns and has a filter applied
      changed = true;
      columnSettings[colName] = { filter_params: null, order: null };
    } else if (visibleColumns.includes(colName) && !colName.includes('calculated_date')) {
      columnSettings[colName] = settings;
    }
  });
  return { clearedCols: visibleColumns, clearedColumnSettings: columnSettings, changedBool: changed };
}

export function buildJQL(task) {
  return [{ field: 'id', operator: 'in', value: [task.client_id] }];
}

// todo: remove after backend does migration
// to avoid backend DB migration headaches, we're mutating the incoming data as if a DB migration happened
// this has to stay in place until backend does a migration
export function taskPropMigration(taskResponse) {
  return {
    ...taskResponse,
    tasks: taskResponse?.tasks?.map(task => {
      const t = task.task;
      // contact_id => client_id
      if (t.contact_id && !t.client_id) {
        t.client_id = t.contact_id;
      }
      // contact_name => client_name
      if (t.contact_name && !t.client_name) {
        t.client_name = t.contact_name;
      }
      return { ...task, task: t };
    }),
  };
}

// todo: remove after backend does migration
// to avoid backend DB migration headaches, we're mutating the incoming data as if a DB migration happened
// this has to stay in place until backend does a migration
export function columnSettingsMigration(setting) {
  // contact_name => client_name
  if (setting[cols.contact] && !setting[cols.client]) {
    setting[cols.client] = setting[cols.contact];
  }
  return setting;
}

// todo: remove after backend does migration
// to avoid backend DB migration headaches, we're mutating the incoming data as if a DB migration happened
// this has to stay in place until backend does a migration
export function visibleColumnMigration(visibleColumns = []) {
  return visibleColumns.map(column => {
    if (column === cols.contact) return cols.client;

    return column;
  });
}
