export const isClientRequest = item =>
  item && (item.request_tools?.length > 0 || item.type === 'client_request' || item.type === 'Client Request');

export const getClientRequestFromTask = task => task.request_tools[0];

export const decimalNumberFilter = ({ value, precision = 2, allowComma = false, allowNegative = false }) => {
  {
    let regExpression;
    if (allowComma && allowNegative) {
      regExpression = /[^0-9.,-]+/g;
    } else if (allowComma) {
      regExpression = /[^0-9.,]+/g;
    } else if (allowNegative) {
      regExpression = /[^0-9.-]+/g;
    } else {
      regExpression = /[^0-9.]+/g;
    }
    var clean = value.replace(regExpression, '');
    const firstDecimal = clean.indexOf('.');
    if (firstDecimal >= 0) {
      //only one decimal point
      let secondDecimal = clean.indexOf('.', firstDecimal + 1);
      if (secondDecimal >= 0) {
        clean = clean.substring(0, secondDecimal);
      } else if (precision) {
        //precision
        const endIndex = Math.min(clean.length, clean.indexOf('.') + precision + 1);
        clean = clean.substring(0, endIndex);
      }
      if (allowComma) {
        let commaAfterDecimal = clean.indexOf(',', firstDecimal + 1);
        if (commaAfterDecimal >= 0) {
          clean = clean.substring(0, commaAfterDecimal);
        }
      }
    }

    if (clean === '.') return '0.';
    return clean;
  }
};

// Builds an object with the value at the given path, where path is an array of field names
// buildObject(['user', 'id'], 1) => {user: {id: 1}}
export function buildObject(path, value) {
  if (path.length > 1 && path[1] === 'assigned_to' && !value) value = []; // assigned_to should be an empty array not 'null'
  if (path.length === 1) {
    return { [path[0]]: value };
  }
  return { [path[0]]: buildObject(path.slice(1), value) };
}
