// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#create-edit-esign-request-styles__taskDueDate--_pPBy {
  border-radius: 0 5px 5px 0;
}

.create-edit-esign-request-styles__round--mDZD3 {
  border-radius: 0 5px 5px 0 !important;
}

.create-edit-esign-request-styles__esignFormCtrl--X7IJU {
  align-items: center;
  display: flex;
  padding: 2px 2px 1px;
}

.create-edit-esign-request-styles__clientChip--MuZxz {
  align-items: center;
  background-color: rgba(59, 155, 252, 0.2);
  border: solid 1px rgba(59, 155, 252, 0.5);
  border-radius: 5px;
  color: rgb(59, 155, 252);
  color: var(--cps-color-vibrant-ocean);
  display: inline-flex;
  height: 25px;
  margin-right: 5px;
  max-width: 184px;
  padding: 4px 6px;
  vertical-align: top;
}

.create-edit-esign-request-styles__clientChipNew--h1DLR {
  background-color: var(--cps-color-pill-bg);
  border: solid 1px var(--cps-color-pill-text);
  color: var(--cps-color-pill-text);
}

.create-edit-esign-request-styles__reminderTrash--J5Ytf {
  position: absolute;
  right: 4px;
  z-index: 3;
}
`, "",{"version":3,"sources":["webpack://./src/create-edit-esign-request/create-edit-esign-request.styles.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,yCAAyC;EACzC,yCAAyC;EACzC,kBAAkB;EAClB,wBAAwB;EACxB,qCAAqC;EACrC,oBAAoB;EACpB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0CAA0C;EAC1C,4CAA4C;EAC5C,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ","sourcesContent":["#taskDueDate {\n  border-radius: 0 5px 5px 0;\n}\n\n.round {\n  border-radius: 0 5px 5px 0 !important;\n}\n\n.esignFormCtrl {\n  align-items: center;\n  display: flex;\n  padding: 2px 2px 1px;\n}\n\n.clientChip {\n  align-items: center;\n  background-color: rgba(59, 155, 252, 0.2);\n  border: solid 1px rgba(59, 155, 252, 0.5);\n  border-radius: 5px;\n  color: rgb(59, 155, 252);\n  color: var(--cps-color-vibrant-ocean);\n  display: inline-flex;\n  height: 25px;\n  margin-right: 5px;\n  max-width: 184px;\n  padding: 4px 6px;\n  vertical-align: top;\n}\n\n.clientChipNew {\n  background-color: var(--cps-color-pill-bg);\n  border: solid 1px var(--cps-color-pill-text);\n  color: var(--cps-color-pill-text);\n}\n\n.reminderTrash {\n  position: absolute;\n  right: 4px;\n  z-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskDueDate": `create-edit-esign-request-styles__taskDueDate--_pPBy`,
	"round": `create-edit-esign-request-styles__round--mDZD3`,
	"esignFormCtrl": `create-edit-esign-request-styles__esignFormCtrl--X7IJU`,
	"clientChip": `create-edit-esign-request-styles__clientChip--MuZxz`,
	"clientChipNew": `create-edit-esign-request-styles__clientChipNew--h1DLR`,
	"reminderTrash": `create-edit-esign-request-styles__reminderTrash--J5Ytf`
};
export default ___CSS_LOADER_EXPORT___;
