import { renameTasksToNotices, isTranscriptsOnly } from './transcripts-only-util.js';

/* Returns the string that should be used to reference "Tasks"
 * For instance transcripts only users should see "Notices" instead of "Tasks"
 * Usage:
 * getTaskStr('task', transcriptOnlyUser)  - returns 'notice'
 * getTaskStr('Task', transcriptOnlyUser)  - returns 'Notice'
 * getTaskStr('tasks', transcriptOnlyUser) - returns 'notices'
 * getTaskStr('Tasks', transcriptOnlyUser) - returns 'Notices'
 */
export function getTaskStr(str, user) {
  if (isTranscriptsOnly(user)) {
    return renameTasksToNotices(str, true);
  } else {
    return str;
  }
}
