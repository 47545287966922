import { fetchAsObservable } from 'fetcher!sofe';
import { pluck } from 'rxjs/operators';

export function getStatuses(serviceType) {
  return fetchAsObservable(`/api/task-statuses?service_type${serviceType ? '=' + serviceType : ''}`).pipe(
    pluck('task_statuses')
  );
}

export function addStatus(userId, status) {
  return fetchAsObservable(`/api/task-statuses`, {
    method: 'POST',
    body: { task_statuses: status },
  }).pipe(pluck('task_statuses'));
}

export function removeStatus(id) {
  return fetchAsObservable(`/api/task-statuses/${id}:archive`, {
    method: 'POST',
  }).pipe(pluck('task_statuses'));
}

export function unremoveStatus(id) {
  return fetchAsObservable(`/api/task-statuses/${id}:unarchive`, {
    method: 'POST',
  }).pipe(pluck('task_statuses'));
}
