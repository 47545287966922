export const priorityOptions = [
  { name: 'High', color: 'var(--cp-color-notification-badge-bg)' },
  { name: 'Medium', color: '#fa8128' }, // orange
  { name: 'Low', color: '#fccd12' }, // yellow
  { name: 'No priority', color: 'var(--cp-color-app-icon)' },
];
export const getPriorityColor = priority => {
  const priorityOption = priorityOptions.find(option => option.name === priority) || {
    color: 'var(--cp-color-app-icon)',
  };
  return priorityOption.color;
};
