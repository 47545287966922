import { fetchAsObservable } from 'fetcher!sofe';
import { intersectionBy, isEmpty } from 'lodash';
import { hasAccess } from 'cp-client-auth!sofe';
import { forkJoin } from 'rxjs';
import { pluck, map as rxMap } from 'rxjs/operators';
const requireAllPermissions = true;

export function getTeammembers(permissions = ['tasks_generic']) {
  return fetchAsObservable(`wg/users?role=TeamMember&sort=name`).pipe(
    pluck('users'),
    rxMap(users => users.filter(user => hasAccess(user)(permissions, requireAllPermissions)))
  );
}

// Get team members with access to the given client, including admins
export function getClientTeammembers(clientId, permissions = ['tasks_generic']) {
  return fetchAsObservable(`/api/clients/${clientId}/users-and-admins?exclude_inactive=true&exclude_deleted=true`).pipe(
    pluck('users'),
    rxMap(users =>
      users?.filter(user => user.role === 'TeamMember' && hasAccess(user)(permissions, requireAllPermissions))
    )
  );
}

// Get common team members across multiple clients
export function getTeammembersForClients(clientIds, permissions) {
  if (isEmpty(clientIds)) {
    return getTeammembers(permissions);
  }
  return forkJoin(clientIds.map(id => getClientTeammembers(id, permissions))).pipe(
    rxMap(results => {
      const commonTeammembers = intersectionBy(...results, tm => tm.id);
      return commonTeammembers;
    })
  );
}

export function getClients(term, operator = 'beginswith') {
  const query = buildQuery(term.toLowerCase(), operator);
  const limit = 100;
  return fetchAsObservable(`/api/clients?limit=${limit}&jql=${encodeURIComponent(JSON.stringify(query))}`).pipe(
    pluck('clients')
  );
}

function buildQuery(term, operator) {
  return [
    {
      field: 'is_archived',
      operator: 'eq',
      value: false,
    },
    {
      field: 'is_active',
      operator: 'eq',
      value: true,
    },
    {
      OR: [
        {
          field: 'person_name',
          operator: operator,
          value: term,
        },
        {
          field: 'business_name',
          operator: operator,
          value: term,
        },
        {
          field: 'last_name',
          operator: operator,
          value: term,
        },
      ],
    },
  ];
}

export function fetchClients(search) {
  return fetchAsObservable(`/api/users/0/client-names?limit=20${search ? `&name=${search}` : ''}`).pipe(
    pluck('clients')
  );
}

export function getClient(id, include) {
  if (include && include.constructor !== Array) {
    throw new Error(`Expected an array but got '${include}' for 'include' parameter to 'getClient()'`);
  }
  return fetchAsObservable(`/api/clients/${id}${include ? `?include=${include.join(',')}` : ''}`).pipe(
    pluck('clients')
  );
}

export function getTimers() {
  return fetchAsObservable(`/api/invoices/timers`).pipe(pluck('timers'));
}

export function getRoles() {
  return fetchAsObservable('/api/roles?include_applied_to=false').pipe(pluck('roles'));
}

export function getTeams() {
  return fetchAsObservable('/wg/user-teams').pipe(pluck('teams'));
}
