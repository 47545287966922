export default Object.freeze({
  DRAFT: 'DRAFT',
  COMPLETED: 'COMPLETED',
  NO_STATUS: 'NOSTATUS',
  NOT_STARTED: 'NOTSTARTED',
  INPROGRESS: 'INPROGRESS',
  REVIEW: 'REVIEW',
  ON_HOLD: 'ONHOLD',
  WITH_CLIENT: 'WITH_CLIENT',
});
