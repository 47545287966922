import React from 'react';
import { useHasAccess } from 'cp-client-auth!sofe';
import { SelectPriorityDropdown } from './select-priority-dropdown.component';

function SelectPriority({ value, onChange, disabled = false }) {
  const canEdit = useHasAccess('tasks_create_edit') && !disabled;
  const emptyCase = 'No priority';

  return (
    <SelectPriorityDropdown
      value={value || emptyCase}
      onChange={priority => onChange(priority === emptyCase ? null : priority)}
      disabled={!canEdit}
    />
  );
}

export default React.memo(SelectPriority);
