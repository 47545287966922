// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* reminders.style.css */

.reminders-styles__remindersContainer--WxXZd {
  position: relative;
  background: #3c464d !important;
  display: flex;
  display: -webkit-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-start;
  width: 100%;
}

.reminders-styles__remindersElement--eCLE7 {
  flex: 0 1 auto;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.reminders-styles__remindersDropdown--xJ7Kr {
  padding: 20px 23px;
}

.reminders-styles__reminderPickerTrigger--jP_9m {
  width: 100%;
  background: white;
}

.reminders-styles__remindersDropdownOptions--rEotf {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#selectReminders .cp-select {
  min-width: 114px !important;
}

#selectReminders .cp-select-outer .cp-select__menu {
  width: 222px !important;
}

.reminders-styles__reminderFormGroup--s6AaA {
  margin-bottom: 16px;
  display: block !important;
}

.reminders-styles__remindersDropdownOptions--rEotf > .cp-select-outer {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/common/reminder-picker/reminders.styles.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,aAAa;EACb,qBAAqB;EACrB,qBAAqB;EACrB,2BAA2B;EAC3B,qBAAqB;EACrB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,OAAO;AACT","sourcesContent":["/* reminders.style.css */\n\n.remindersContainer {\n  position: relative;\n  background: #3c464d !important;\n  display: flex;\n  display: -webkit-flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-content: center;\n  align-items: flex-start;\n  width: 100%;\n}\n\n.remindersElement {\n  flex: 0 1 auto;\n  display: flex;\n  display: -webkit-flex;\n  align-items: center;\n}\n\n.remindersDropdown {\n  padding: 20px 23px;\n}\n\n.reminderPickerTrigger {\n  width: 100%;\n  background: white;\n}\n\n.remindersDropdownOptions {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n:global(#selectReminders) :global(.cp-select) {\n  min-width: 114px !important;\n}\n\n:global(#selectReminders) :global(.cp-select-outer) :global(.cp-select__menu) {\n  width: 222px !important;\n}\n\n.reminderFormGroup {\n  margin-bottom: 16px;\n  display: block !important;\n}\n\n.remindersDropdownOptions > :global(.cp-select-outer) {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"remindersContainer": `reminders-styles__remindersContainer--WxXZd`,
	"remindersElement": `reminders-styles__remindersElement--eCLE7`,
	"remindersDropdown": `reminders-styles__remindersDropdown--xJ7Kr`,
	"reminderPickerTrigger": `reminders-styles__reminderPickerTrigger--jP_9m`,
	"remindersDropdownOptions": `reminders-styles__remindersDropdownOptions--rEotf`,
	"reminderFormGroup": `reminders-styles__reminderFormGroup--s6AaA`
};
export default ___CSS_LOADER_EXPORT___;
