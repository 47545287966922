// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-select-custom-styles__hasError--SSFOS {
  border: 1px solid var(--cp-color-app-error-text);
  border-radius: var(--cp-form-border-radius);
}`, "",{"version":3,"sources":["webpack://./src/create-edit-task/basic-information/multi-select-custom.styles.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,2CAA2C;AAC7C","sourcesContent":[".hasError {\n  border: 1px solid var(--cp-color-app-error-text);\n  border-radius: var(--cp-form-border-radius);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hasError": `multi-select-custom-styles__hasError--SSFOS`
};
export default ___CSS_LOADER_EXPORT___;
