export const reminderIntervalsMap = {
  weekday: {
    shortDescription: 'Weekdays',
    fullDescription: 'Weekday',
  },
  'every-mwf': {
    shortDescription: 'Mon, Wed, Fri',
    fullDescription: 'Monday, Wednesday, Friday',
  },
  'every-tth': {
    shortDescription: 'Tue, Thu',
    fullDescription: 'Tuesday, Thursday',
  },
  'every-m': {
    shortDescription: 'Mondays',
    fullDescription: 'Mondays',
  },
};

export const expirationIntervalsMap = {
  FIFTEEN: '15',
  THIRTY: '30',
  FORTY_FIVE: '45',
  SIXTY: '60',
};

export const reminderIntervalOptions = [
  { key: 'weekday', value: 'Weekday' },
  { key: 'every-mwf', value: 'Monday, Wednesday, Friday' },
  { key: 'every-tth', value: 'Tuesday, Thursday' },
  { key: 'every-m', value: 'Mondays' },
];

export const expirationIntervalOptions = [
  { key: 15, value: '15 days' },
  { key: 30, value: '30 days' },
  { key: 45, value: '45 days' },
  { key: 60, value: '60 days' },
];
