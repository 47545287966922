import React, { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { CpButton, CpCard, CpIcon } from 'canopy-styleguide!sofe';
import { resendEsignRequest } from 'src/resources/esign.resource';
import { handleError } from 'src/common/error.helper';

import styles from './esign-file-well.styles.css';

export function EsignFileWellSigner({ signer, taskId, task }) {
  const signerResentInfo = task?.request_tools[0].relationships.assigned_to.find(relationship =>
    signer.signer_id
      ? relationship.signer_id === signer.signer_id
      : !relationship.signer_id && relationship.user_id === signer.signatory_user_id
  );

  const [resent, setResent] = useState(false);
  const [resentDate, setResentDate] = useState(
    signerResentInfo?.last_resent_at
      ? DateTime.fromISO(signerResentInfo.last_resent_at).toLocaleString(DateTime.DATE_SHORT)
      : null
  );

  const signingChipStyle = useMemo(() => {
    return signer.practitioner ? styles.signerChipGreen : styles.signerChipBlue;
  }, [signer]);

  const handleClick = useCallback(() => {
    resendEsignRequest(taskId, signer).subscribe(() => {
      setResent(true);
    }, handleError);
    setResentDate(DateTime.now().toLocaleString(DateTime.DATE_SHORT));
  }, [signer, taskId]);

  return (
    <div>
      <CpCard className={`${styles.fileCardResend}`}>
        {!signer.signed ? (
          <CpIcon name="shape-circle-dashed" fill="var(--cp-color-app-icon)" />
        ) : (
          <CpIcon name="checkmark-circle-filled-large" fill="var(--cp-color-app-primary)" />
        )}
        <div className={`${styles.signerChipResend} ${signingChipStyle}`}>
          <div className="cp-ellipsis cp-text-center">{signer.signatory_user_name}</div>
        </div>
        {!signer.signed ? (
          <>
            {resentDate ? (
              <div className={`${styles.signatureResentInfo}`}>Resent {resentDate}</div>
            ) : (
              <div className={`${styles.signatureInfo}`}>No Signature</div>
            )}
          </>
        ) : (
          <div className={`${styles.signatureInfo}`}>
            Signed {DateTime.fromISO(signer.completed_at).toLocaleString(DateTime.DATE_SHORT)}
          </div>
        )}
        {!signer.signed && (
          <CpButton
            className={`${styles.noShrinkButton} ${styles.resendButton}`}
            btnType="flat"
            disabled={resent}
            onClick={handleClick}
          >
            Resend
          </CpButton>
        )}
      </CpCard>
    </div>
  );
}
