import React, { Suspense, useState, useEffect } from 'react';
import { partial, cloneDeep, get, set, uniqBy, trim } from 'lodash';
import { DateTime } from 'luxon';

import { CprDatepicker, CpIcon, CpButton, CpModal, CpLoader } from 'canopy-styleguide!sofe';
import { Preview } from 'docs-ui!sofe';
import { hasAccess } from 'cp-client-auth!sofe';
import { EsignSigningParcel } from 'signing-ui!sofe';

import StatusInput from 'src/common/status-input.component';
import TeammemberSelect from 'src/create-edit-task/basic-information/teammember-select.component';
import EsignFileWell from './esign-file-well.component';
import ReminderPicker from '../common/reminder-picker/reminder-picker.component';
import SelectPriority from 'src/common/select-priority/select-priority.component';
import { reminderIntervalsMap } from '../common/reminder-picker/reminder.constants';
import { patchTask } from 'src/resources/tasks.resource';
import { getEsignDoc } from 'src/resources/esign.resource';
import { getClient } from 'src/resources/utils.resource';
import { handleError } from 'src/common/error.helper';

import styles from './create-edit-esign-request.styles.css';

const assignedToPath = 'relationships.assigned_to';

const CreateEditEsignRequest = ({
  task,
  clientId,
  taskUpdated,
  close,
  statuses,
  user,
  updateAsyncData,
  teammembers,
}) => {
  const [taskInstance, setTaskInstance] = useState(cloneDeep(task));
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [postingTask, setPostingTask] = useState(false);
  const [signers, setSigners] = useState([]);
  const [completedEsignFileId, setCompletedEsignFileId] = useState(null);
  const [chosenReminder, setChosenReminder] = useState(
    task.request_tools[0] && task.request_tools[0].reminder_data
      ? reminderIntervalsMap[task.request_tools[0].reminder_data.interval]
      : {}
  );
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDocumentPreviewModal, setShowDocumentPreviewModal] = useState(false);
  const [esignDoc, setEsignDoc] = useState({});
  const [showTaskModal, setShowTaskModal] = useState(true);

  const docId = task?.request_tools?.[0]?.tool_type_id;
  const { title: requestTitle = '' } = taskInstance.request_tools.length > 0 ? taskInstance.request_tools[0] : {};
  const taskNameIsValid = !!taskInstance.name;
  const disableSubmit = !formIsDirty || !taskNameIsValid || postingTask;
  const hasRequestToolData = taskInstance.request_tools[0] && taskInstance.request_tools[0].reminder_data;
  const reminderData = hasRequestToolData ? taskInstance.request_tools[0].reminder_data : {};
  const canCreateEdit = hasAccess(user)('tasks_create_edit');
  const canChangeStatus = hasAccess(user)('tasks_status');

  useEffect(() => {
    setTaskInstance(cloneDeep(task));
  }, [task]);

  useEffect(() => {
    if (docId) {
      const obs = getEsignDoc(docId).subscribe(esignDoc => {
        const signers = uniqBy(
          esignDoc.signing_locations,
          location => `${location.signatory_user_id}${location.signer_id ? location.signer_id : ''}`
        ).map(({ signatory_user_id, signatory_user_name, signatory_user_role, signed, completed_at, signer_id }) => ({
          signatory_user_id,
          signatory_user_name,
          practitioner: signatory_user_role !== 'Client',
          signed,
          completed_at,
          signer_id,
        }));
        setSigners(signers);
        setCompletedEsignFileId(esignDoc.completed_file_id);
        setEsignDoc(esignDoc);
      }, handleError);
      return () => obs.unsubscribe();
    }
  }, [docId]);

  useEffect(() => {
    const obs = getClient(clientId).subscribe(client => {
      setClients([client]);
      setLoading(false);
    }, handleError);

    return () => obs.unsubscribe();
  }, [clientId]);

  const updateTask = (field, data) => {
    // Unwrap value from Events
    let val = unwrapData(data);

    let task = cloneDeep(taskInstance);
    if (field === assignedToPath) {
      val = val.currentTeammembers;
    }
    if (field === 'dates.0' && !val) {
      task.dates = [];
    } else {
      set(task, field, val);
    }
    setTaskInstance(task);
    setFormIsDirty(true);
  };

  const unwrapData = data => {
    if (data?.nativeEvent && data.nativeEvent instanceof Event) {
      // for native inputs
      return data.target.value;
    } else if (data instanceof CustomEvent || data?.detail) {
      const date = DateTime.fromJSDate(data.detail);
      return date.isValid
        ? createDueDateObj(date.toISODate()) // for CustomEvent from DatePicker
        : data.detail;
    } else {
      return data;
    }
  };

  const onSetReminder = (i, interval, expirationInterval, descriptionObj) => {
    updateTask(`request_tools.0.reminder_data`, {
      expiration_interval_days: parseInt(expirationInterval, 10),
      interval,
    });
    setChosenReminder(descriptionObj);
  };

  const createDueDateObj = date => ({
    date,
    date_type_id: 'DUEDATE',
    relative: null,
  });

  const handleUpdateTask = () => {
    const task = { ...taskInstance, name: trim(taskInstance.name) };
    setPostingTask(true);
    patchTask({ ids: task.id, task }).subscribe(updatedTask => {
      taskUpdated(updatedTask.tasks[0]);
      close();
    }, handleError);
  };

  const removeReminder = () => {
    const task = { ...taskInstance };
    set(task, 'request_tools.0.reminder_data', null);
    setTaskInstance(task);
    setChosenReminder(null);
    setFormIsDirty(true);
  };

  const openEsign = () => {
    setShowTaskModal(false);

    const esignOptions = {
      context: { loggedInUser: user },
      title: `Review Esign Doc: ${esignDoc.name}`,
      documentId: esignDoc.id,
      clientId: `${task.client_id}`,
      clientRequestId: task.id,
      modalClosed: () => {
        setShowTaskModal(true);
      },
    };

    EsignSigningParcel.showSigningModal({
      ...esignOptions,
      documentType: 'file',
      esignDocument: esignDoc,
    });
  };

  const toggleFilePreview = () => setShowDocumentPreviewModal(!showDocumentPreviewModal);

  return showDocumentPreviewModal ? (
    <Suspense fallback={<CpLoader />}>
      <Preview
        doc={{ id: completedEsignFileId }}
        actions={['print', 'download', 'viewDetails', 'openInNewTab']}
        handleClosePreview={toggleFilePreview}
      />
    </Suspense>
  ) : (
    <CpModal show={showTaskModal} onClose={close} width={530} zIndex={1} here={2}>
      <CpModal.Header title="Edit eSign request">
        <div>
          <StatusInput
            statuses={statuses}
            updateStatuses={partial(updateAsyncData, 'statuses')}
            userId={user.id}
            updateTaskStatus={partial(updateTask, 'status_id')}
            selectedStatus={taskInstance.status_id}
            disabled={!canCreateEdit || !canChangeStatus}
          />
        </div>
      </CpModal.Header>
      <CpModal.Body>
        <h5 className="cp-m-0 cp-mb-16 cps-wt-bold cps-subheader-sm">Basic information</h5>
        <form className="cps-form-horizontal">
          <div className={`cps-form-group ${!taskNameIsValid ? 'cps-has-error' : null}`}>
            <label htmlFor="taskName" className="cps-col-xs-3 cps-control-label">
              Task name
            </label>
            <div className="cps-col-xs-9">
              <input
                type="text"
                id="taskName"
                className="cps-form-control"
                placeholder="Task name"
                value={taskInstance.name}
                onChange={partial(updateTask, 'name')}
                disabled={!canCreateEdit}
                required
              />
            </div>
          </div>
          <div className="cps-form-group">
            <label className="cps-col-xs-3 cps-control-label">Priority</label>
            <div className="cps-col-xs-9">
              <SelectPriority value={taskInstance.priority} onChange={partial(updateTask, 'priority')} />
            </div>
          </div>
          <div className="cps-form-group">
            <label htmlFor="" className="cps-col-xs-3 cps-control-label">
              Client
            </label>
            <div className="cps-col-xs-9">
              <div className={`cps-form-control ${styles.esignFormCtrl}`} disabled>
                {loading
                  ? 'loading...'
                  : clients.map(({ name }, i) => (
                      <span
                        className={`cps-ellipsis ${styles.clientChip} ${styles.clientChipNew}`}
                        key={`${i}-${name}`}
                      >
                        {name}
                      </span>
                    ))}
              </div>
            </div>
          </div>
          <div className="cps-form-group">
            <label className="cps-col-xs-3 cps-control-label">Assignees</label>
            <div className="cps-col-xs-9">
              <TeammemberSelect
                possibleTeammembers={teammembers}
                teammembers={get(taskInstance, assignedToPath)}
                selectTeammembers={partial(updateTask, assignedToPath)}
                disabled={!canCreateEdit}
              />
            </div>
          </div>
          <div className="cps-form-group">
            <label htmlFor="taskDueDate" className="cps-col-xs-3 cps-control-label">
              Client Due date
            </label>
            <div className="cps-col-xs-9">
              <div className="cps-input-group">
                <span className="cps-input-group-addon cps-icon-addon" aria-hidden>
                  <CpIcon name="misc-calendar" />
                </span>
                <CprDatepicker
                  date={get(taskInstance, 'dates.0.date', null)}
                  events={{
                    datechange: partial(updateTask, 'dates.0'),
                  }}
                  format={'MMMM DD, YYYY'}
                  orientation="bottom"
                  containerClass={`cps-margin-0`}
                  id={styles.taskDueDate}
                  disabled={!canCreateEdit}
                />
              </div>
            </div>
          </div>
          <div className="cps-form-group">
            <label htmlFor="clientReminders" className="cps-col-xs-3 cps-control-label">
              Client Reminder
            </label>
            <div className="cps-col-xs-9">
              <div className="cps-input-group">
                <span className="cps-input-group-addon cps-icon-addon" aria-hidden>
                  <CpIcon name="misc-alarm-clock" />
                </span>
                <ReminderPicker
                  setReminder={partial(onSetReminder, 0)}
                  inputClass={styles.round}
                  description={`${
                    chosenReminder && chosenReminder.shortDescription
                      ? `Every ${chosenReminder.shortDescription} for ${reminderData.expiration_interval_days} days`
                      : ''
                  }`}
                  reminders={reminderData}
                  disabled={!canCreateEdit}
                >
                  {chosenReminder && chosenReminder.shortDescription && canCreateEdit && (
                    <CpButton
                      icon="crud-trash-small"
                      aria-label="Remove reminder"
                      className={`${styles.reminderTrash}`}
                      onClick={removeReminder}
                    />
                  )}
                </ReminderPicker>
              </div>
            </div>
          </div>
        </form>
        <EsignFileWell
          requestTitle={requestTitle}
          signers={signers}
          completedEsignFileId={completedEsignFileId}
          toggleFilePreview={toggleFilePreview}
          openEsign={openEsign}
          kba={esignDoc.kba_enabled}
          taskId={taskInstance.id}
          task={taskInstance}
        />
      </CpModal.Body>
      <CpModal.Footer>
        <div>
          <CpButton disabled={disableSubmit} onClick={handleUpdateTask} id="submitEditEsignRequest">
            Update task
          </CpButton>
          <CpButton btnType="flat" id="closeEditEsignRequestModal" onClick={close}>
            Cancel
          </CpButton>
          {!taskNameIsValid && (
            <span className="cps-has-error cps-pull-right" style={{ paddingTop: '6px' }}>
              Please enter a task name
            </span>
          )}
        </div>
      </CpModal.Footer>
    </CpModal>
  );
};

export default CreateEditEsignRequest;
