//@flow
import { cloneDeep, get } from 'lodash';
import type { Service } from 'src/service-workspace/service-workspace.types.js';

type Analytic = {
  ancestor_type?: string, // the action of inheritance ('copy', 'template', ... etc.)
  ancestor_id?: string, // the id of the obj the tracked obj is inheriting from. If ancestor_type is tracked this should be accompanied with it.
};

export function addAnalyticsObjectToService(analyticsObj: Analytic = {}, serviceObj: Service) {
  const service = cloneDeep(serviceObj);

  service.ancestor_type = analyticsObj.ancestor_type;
  service.ancestor_id = analyticsObj.ancestor_id;

  get(service, 'reminders', []).forEach(r => {
    r.ancestor_type = analyticsObj.ancestor_type;
    r.ancestor_id = analyticsObj.ancestor_id;
  });

  get(service, 'dates', []).forEach(d => {
    d.ancestor_type = analyticsObj.ancestor_type;
    d.ancestor_id = analyticsObj.ancestor_id;
  });

  get(service, 'relationships.files', []).forEach(f => {
    f.ancestor_type = analyticsObj.ancestor_type;
    f.ancestor_id = analyticsObj.ancestor_id;
  });

  get(service, 'subtasks', []).forEach(s => {
    s.ancestor_type = analyticsObj.ancestor_type;
    s.ancestor_id = analyticsObj.ancestor_id;

    get(s, 'reminders', []).forEach(r => {
      r.ancestor_type = analyticsObj.ancestor_type;
      r.ancestor_id = analyticsObj.ancestor_id;
    });

    get(s, 'dates', []).forEach(d => {
      d.ancestor_type = analyticsObj.ancestor_type;
      d.ancestor_id = analyticsObj.ancestor_id;
    });

    get(s, 'relationships.files', []).forEach(f => {
      f.ancestor_type = analyticsObj.ancestor_type;
      f.ancestor_id = analyticsObj.ancestor_id;
    });
  });

  return service;
}
