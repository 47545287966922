export default Object.freeze({
  EDIT: 'edit',
  COPY: 'copy',
  BULK: 'bulk',
  CREATE: 'create',
});

export const taskModeConstantsCapital = {
  EDIT: 'Edit',
  edit: 'Edit',
  COPY: 'Copy',
  copy: 'Copy',
  BULK: 'Bulk',
  bulk: 'Bulk',
  CREATE: 'Create',
  create: 'Create',
};
