import { warningToast } from 'toast-service!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import { deleteDependentDates } from 'src/create-edit-task/create-edit-task.helpers';
import { createClient, createContact, createNotice } from 'src/resources/notice.resource';
import { patchTask, postTask } from 'src/resources/tasks.resource';
import DateTypes from 'src/constants/date-types.constants';
import { getUserClientsAccess } from 'src/resources/bulk-tasks.resource';
import { findDateIndex } from 'src/service-workspace/common/common.helpers';
import { dateHasDependents } from 'src/service-workspace/service-workspace.helper';
import { cloneDeep, forEach, map, noop, uniqBy } from 'lodash';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { isClientRequest } from '../tasks.helper';
import { handleError } from 'src/common/error.helper';

export const typeConstants = {
  task: 'Task',
  subtask: 'Subtask',
  notice: 'Notice',
};

export const pastTenseModes = {
  create: 'created',
  edit: 'edited',
  copy: 'copied',
  bulk: 'created',
};

export function buildNoticeBody(
  {
    budgeted_hours,
    dates,
    name,
    priority,
    relationships,
    status_id,
    template_id,
    type,
    client_id,
    description,
    return_type,
    tax_year,
  },
  setLoadingCb,
  newClientCreated = false,
  onClose = noop
) {
  delete relationships.notification_recipients;
  let payload = {
    notice: {
      template_id: template_id,
      type: type,
      task: {
        budgeted_hours,
        dates,
        name,
        priority,
        relationships,
        status_id,
        description,
        return_type,
        tax_year,
      },
    },
  };
  return createNotice(payload, client_id)
    .toPromise()
    .then(
      res => ({ ...res, client_id }),
      err => {
        if (newClientCreated) {
          warningToast({
            message: `The notice failed to create, but a client for ${newClientCreated.name} was still created.`,
            links: [{ label: `View ${newClientCreated.name}`, url: `/#/tasks/clients/${newClientCreated.id}` }],
          });
          onClose();
        }
        setLoadingCb(false);
        handleError(err);
      }
    );
}

export function buildClient(clientInformation, notice, setLoadingCb, onClose) {
  return createClient({ clients: clientInformation })
    .toPromise()
    .then(
      res => {
        return buildNoticeBody({ ...notice, client_id: res.id }, setLoadingCb, res, onClose);
      },
      err => {
        setLoadingCb(false);
        handleError(err);
      }
    );
}

export async function buildNewCrmClient(contactDto, clientDto, notice, setLoadingCb, onClose) {
  if (!clientDto.contacts[0].id) {
    const response = await createContact(contactDto).toPromise();
    clientDto.contacts = [{ id: response.contact?.id, contact_type: 'primary' }];
  }

  return buildClient(clientDto, notice, setLoadingCb, onClose);
}

export function formatClientInfo(clientInfo, notice, setLoadingCb, onClose) {
  clientInfo.first_name = clientInfo.first_name ? clientInfo.first_name.trim() : '';
  clientInfo.middle_name = clientInfo.middle_name ? clientInfo.middle_name.trim() : '';
  clientInfo.last_name = clientInfo.last_name ? clientInfo.last_name.trim() : '';
  clientInfo.business_name = clientInfo.business_name ? clientInfo.business_name.trim() : '';
  return buildClient(clientInfo, notice, setLoadingCb, onClose);
}

export function formatNewCrmClientInfo(clientInfo, notice, setLoadingCb, onClose) {
  const contactDto = {
    first_name: clientInfo.firstName,
    last_name: clientInfo.lastName,
    tin: clientInfo.ssn,
    emails: clientInfo.email
      ? [
          {
            is_primary: true,
            key: clientInfo.emailType,
            value: clientInfo.email,
          },
        ]
      : undefined,
  };

  const clientDto = {
    name: clientInfo.clientName,
    business_name: clientInfo.businessName,
    client_type: 'client',
    is_active: true,
    is_business: clientInfo.isBusiness,
    tin: clientInfo.tin,
    contacts: [{ id: clientInfo.contact?.id, contact_type: 'primary' }],
  };

  return buildNewCrmClient(contactDto, clientDto, notice, setLoadingCb, onClose);
}

export function editNotice(notice, setLoadingCb) {
  let payload = {
    ...notice,
    service_type: 'notice',
    service_fields: { notice_type: notice.type },
  };
  return patchTask({ ids: notice.id, task: payload })
    .toPromise()
    .then(
      res => res.tasks[0],
      err => {
        setLoadingCb(false);
        handleError(err);
      }
    );
}

export function validateNotice(notice, noticeClientOption, noticeClientInformation, isCreateMode, isFormValid) {
  const hasExisitingClient = !!notice.client_id;
  const hasNoticeType = (!!notice.type && !!notice.template_id) || !isCreateMode;
  if (noticeClientOption.name === 'New client') {
    if (hasNoticeType && isFormValid) {
      return true;
    }
    const tin = !!noticeClientInformation.tin ? noticeClientInformation.tin.length === 9 : true;
    if (noticeClientInformation.is_business) {
      const hasBusinessName = !!noticeClientInformation.business_name?.trim();
      return hasNoticeType && hasBusinessName && tin;
    } else {
      const hasFirstName = !!noticeClientInformation.first_name?.trim();
      const hasLastName = !!noticeClientInformation.last_name?.trim();
      return hasNoticeType && hasFirstName && hasLastName && tin;
    }
  } else {
    return hasNoticeType && hasExisitingClient;
  }
}

export function clearRelativeDates(serviceCopy) {
  if (serviceCopy.datesLeftEmptyError) {
    forEach(serviceCopy.datesLeftEmptyError, (errorBool, dateType) => {
      if (errorBool) {
        serviceCopy = deleteDependentDates({
          rootTask: serviceCopy,
          dateTypeId: dateType,
          dateParentTaskId: serviceCopy.id,
        });
      }
    });
  }
  return serviceCopy;
}

function isValidReminder(reminder) {
  return !!reminder.interval_unit && !!reminder.relationships.date_type_id;
}

function isValidDate(date) {
  return !!date.date_type_id && (!!date.date || !!date.relative);
}

function clearServiceForPost({ serviceCopy, hasHoursPermission }) {
  serviceCopy = clearRelativeDates(serviceCopy);
  serviceCopy = buildCRSubtasks({ service: serviceCopy });
  // Remove dates that are empty
  serviceCopy.dates = serviceCopy.dates.filter(isValidDate);
  // Remove reminders that are empty
  serviceCopy.reminders = serviceCopy.reminders.filter(isValidReminder);
  serviceCopy.subtasks = serviceCopy.subtasks
    ?.filter(sub => sub.name)
    ?.map(sub => {
      sub.dates = sub.dates?.filter(isValidDate);
      sub.reminders = sub.reminders?.filter(isValidReminder);
      if (sub.editorRef) {
        sub[isClientRequest(sub) ? 'message' : 'description'] = sub.editorRef.current.getHTML();
        delete sub.editorRef;
      }
      sub.budgeted_hours = +sub.budgeted_hours || 0;
      if (featureEnabled('toggle_hours_perm') && !hasHoursPermission) {
        delete sub.budgeted_hours;
      }
      return clearRelativeDates(sub);
    });
  if (serviceCopy.email) {
    serviceCopy.email = { account_id: serviceCopy.email.canopy_account_id, thread_id: serviceCopy.email.id };
  }
  return serviceCopy;
}

export function createTaskPostObject({ serviceCopy, hasHoursPermission }) {
  return postTask(clearServiceForPost({ serviceCopy, hasHoursPermission })).toPromise();
}

function buildCRSubtasks({ service }) {
  let serviceCopy = cloneDeep(service);
  serviceCopy.subtasks = serviceCopy.subtasks.map(sub => {
    if (isClientRequest(sub)) {
      sub.status = 'DRAFT';
      sub.type = 'client_request';
    }
    sub.tool_type = 'simple';
    return sub;
  });
  return serviceCopy;
}

export function buildAndCreateBulkTask({ serviceCopy, snapshotId }) {
  serviceCopy = clearServiceForPost({ serviceCopy });
  // Remove dates that are empty
  serviceCopy.dates = serviceCopy.dates.filter(isValidDate);
  // Remove reminders that are empty
  serviceCopy.reminders = serviceCopy.reminders.filter(isValidReminder);
  serviceCopy.subtasks = serviceCopy.subtasks.map(sub => {
    sub.dates = sub.dates?.filter(isValidDate);
    sub.reminders = sub.reminders?.filter(isValidReminder);
    return sub;
  });
  return postTask({ ...buildCRSubtasks({ service: serviceCopy }), client_list_snapshot_id: snapshotId }).pipe(first());
}

export function setStartAndDue(dates) {
  let startDateObj = {};
  let dueDateObj = {};
  dates.map(date => {
    if (date.date_type_id === DateTypes.DUE) {
      dueDateObj = date;
    } else if (date.date_type_id === DateTypes.START) {
      startDateObj = date;
    }
  });
  return { startDateObj, dueDateObj };
}

function getInvalidAssigneeList({ allAssigneeIds, allAssignees, setInvalidAssignees, snapshotArr, addToList }) {
  if (allAssigneeIds.length) {
    const getAccess = snapshotId => getUserClientsAccess(snapshotId, [allAssigneeIds]).pipe(first());
    forkJoin(snapshotArr.map(snapshot => getAccess(snapshot))).subscribe(clientsArrays => {
      const invalidAssignees = [];
      clientsArrays.forEach(obj =>
        map(obj, (val, key) => {
          if (val.length) {
            let index = invalidAssignees.findIndex(invalid => invalid.id === key);
            if (index >= 0) {
              invalidAssignees[index].clients.push(...val);
            } else {
              let assigneeObj = allAssignees.find(assignee => assignee.id === key);
              let invalidObj = { ...assigneeObj, clients: val };
              invalidAssignees.push(invalidObj);
            }
          }
        })
      );
      if (invalidAssignees.length) {
        return setInvalidAssignees(prevInvalid =>
          uniqBy([...(addToList ? prevInvalid : []), ...invalidAssignees], 'id')
        );
      } else if (!addToList) {
        return setInvalidAssignees([]);
      }
    }, handleError);
  } else {
    return setInvalidAssignees([]);
  }
}

function getUniqueAssignees({ assignees, allAssigneeIds, allAssignees }) {
  assignees.map(assignee => {
    if (!allAssigneeIds.includes(assignee.id)) {
      allAssigneeIds.push(assignee.id);
      allAssignees.push(assignee);
    }
  });
  return [allAssignees, allAssigneeIds];
}

function getAssigneesFromService({ service, allAssignees, allAssigneeIds }) {
  let [assigneesArr, assigneeIdsArr] = getUniqueAssignees({
    assignees: service.relationships.assigned_to || service.relationships.team_members,
    allAssigneeIds,
    allAssignees,
  });
  if (Array.isArray(service.reminders) && service.reminders?.length) {
    service.reminders.map(reminder => {
      [assigneesArr, assigneeIdsArr] = getUniqueAssignees({
        assignees: reminder.relationships.notification_recipients,
        allAssignees: assigneesArr,
        allAssigneeIds: assigneeIdsArr,
      });
    });
  }
  return [assigneesArr, assigneeIdsArr];
}

function getAssigneesFromRules({
  allRules,
  allAssignees,
  allAssigneeIds,
  parentId,
  setAssigneeRuleMap,
  isClientRequest,
}) {
  let assigneeRuleMap = {};
  if (Object.keys(allRules).length) {
    function setAssignees(rule, taskId) {
      const action = rule.actions[0];
      if (
        (action.type === 'NOTIFICATION_SEND' && action.data.notification_type === 'certain_team_members') ||
        action.type === 'TASK_SET_ASSIGNEE'
      ) {
        if (action.data.fullTeamMembers) {
          allAssignees.push(...action.data.fullTeamMembers);
        }
        (action.data.assigned_to ? action.data.assigned_to : action.data.user_ids).forEach(user => {
          assigneeRuleMap[user] = {
            ...(assigneeRuleMap[user] || {}),
            ...(parentId === taskId || isClientRequest
              ? { parent: (assigneeRuleMap[user]?.parent || 0) + 1 }
              : { subtask: (assigneeRuleMap[user]?.subtask || 0) + 1 }),
          };
        });
        allAssigneeIds.push(...(action.data.assigned_to ? action.data.assigned_to : action.data.user_ids));
      }
    }
    forEach(allRules, (rulesArr, taskId) => {
      if (Array.isArray(rulesArr)) {
        rulesArr.forEach(rule => {
          setAssignees(rule, taskId);
        });
      } else {
        setAssignees(rulesArr);
      }
    });
    setAssigneeRuleMap?.(assigneeRuleMap);
  }
  return [allAssignees, allAssigneeIds];
}

function buildAllAssignees({ service, allRules, setAssigneeRuleMap, isClientRequest }) {
  let [allAssignees, allAssigneeIds] = getAssigneesFromService({ service, allAssignees: [], allAssigneeIds: [] });
  service.subtasks?.map(subtask => {
    [allAssignees, allAssigneeIds] = getAssigneesFromService({ service: subtask, allAssignees, allAssigneeIds });
  });
  if (allRules && Object.keys(allRules)?.length) {
    [allAssignees, allAssigneeIds] = getAssigneesFromRules({
      allRules,
      allAssignees,
      allAssigneeIds,
      parentId: service.id,
      setAssigneeRuleMap,
      isClientRequest,
    });
  }
  return [allAssignees, allAssigneeIds];
}

export function buildFullInvalidAssignees({
  setInvalidAssignees,
  service,
  snapshotArr,
  allRules,
  teamMembers,
  setAssigneeRuleMap,
  isClientRequest,
}) {
  const [allAssignees, allAssigneeIds] = buildAllAssignees({ service, allRules, setAssigneeRuleMap, isClientRequest });
  getInvalidAssigneeList({
    allAssigneeIds,
    allAssignees: teamMembers || allAssignees,
    setInvalidAssignees,
    snapshotArr,
  });
}

export function buildInvalidAssignees({
  isRemovedFromList,
  setInvalidAssignees,
  lastModifiedMember,
  snapshotArr,
  service,
  allRules,
}) {
  const [, allAssigneeIds] = buildAllAssignees({ service, allRules });
  if (isRemovedFromList && !allAssigneeIds.includes(lastModifiedMember.id)) {
    return setInvalidAssignees(prevInvalidAssignees =>
      prevInvalidAssignees.filter(a => a.id !== lastModifiedMember.id)
    );
  } else if (!isRemovedFromList && !allAssigneeIds.includes(lastModifiedMember.id)) {
    getInvalidAssigneeList({
      allAssigneeIds: [lastModifiedMember.id],
      allAssignees: [lastModifiedMember],
      setInvalidAssignees,
      snapshotArr,
      addToList: true,
    });
  }
}

export function handleAddDate({ serviceDates, dateType, date, dateIndex, editService, service }) {
  let dates = cloneDeep(serviceDates);
  if (!dateIndex) {
    dateIndex = findDateIndex(dateType, dates);
  }
  if (dateIndex >= 0) {
    dates.splice(dateIndex, 1, date);
  } else {
    dates = [...dates, date];
  }
  const datesLeftEmptyError = cloneDeep(service.datesLeftEmptyError) || {};
  datesLeftEmptyError[dateType] = false;
  editService({ dates, datesLeftEmptyError });
}

export const handleRemoveDate = ({ e, dateType, serviceDates, parentService, service, editService, dateIndex }) => {
  e.stopPropagation();
  const newDates = cloneDeep(serviceDates);
  if (!dateIndex) {
    dateIndex = findDateIndex(dateType, newDates);
  }
  if (['STARTDATE', 'DUEDATE'].includes(dateType)) {
    newDates.splice(dateIndex, 1);
  } else {
    newDates[dateIndex] = {
      ...(serviceDates[dateIndex].date_type_id
        ? { date_type_id: serviceDates[dateIndex].date_type_id, date_type_name: serviceDates[dateIndex].date_type_name }
        : {}),
    };
  }
  let dependents;
  if (parentService) {
    dependents = parentService?.subtasks && dateHasDependents(serviceDates[dateIndex], service.id, parentService);
  } else {
    dependents = service?.subtasks && dateHasDependents(serviceDates[dateIndex], service.id, service);
  }
  const datesLeftEmptyError = cloneDeep(service.datesLeftEmptyError) || {};
  datesLeftEmptyError[dateType] = dependents;
  editService({ dates: newDates, datesLeftEmptyError });
};

export const clearReminders = (task, oldDateType) => {
  // If a reminder was set for a date that's being removed, delete the reminder
  return task.reminders.filter(reminder => reminder.relationships?.date_type_id !== oldDateType);
};

// Deletes relative dates and reminders and deletes the current date input
export function deleteDateInputAndRelatives({
  e,
  dates,
  i,
  dateType,
  parentService,
  service,
  subtaskIndex,
  setParentService,
  setInvalidAssignees,
  snapshotArr,
  teamMembers,
}) {
  e.stopPropagation();
  const newDates = cloneDeep(dates);
  if (!i) {
    i = findDateIndex(dateType, newDates);
  }
  if (['STARTDATE', 'DUEDATE'].includes(dateType)) {
    newDates.splice(i, 1);
  } else {
    newDates[i] = {
      ...(dates[i].date_type_id
        ? { date_type_id: dates[i].date_type_id, date_type_name: dates[i].date_type_name }
        : {}),
    };
  }
  let parentTask = cloneDeep(parentService ? parentService : service);
  if (subtaskIndex >= 0) {
    parentTask.subtasks[subtaskIndex].dates = newDates;
  } else {
    parentTask.dates = newDates;
  }
  parentTask = deleteDependentDates({
    rootTask: parentTask,
    dateTypeId: dates[i].date_type_id,
    dateParentTaskId: service.id,
  });
  if (snapshotArr?.length) {
    buildFullInvalidAssignees({ setInvalidAssignees, service: parentTask, snapshotArr, teamMembers });
  }
  setParentService(parentTask);
}

export function formatCopiedClientRequestSubtasks(service) {
  const clonedService = cloneDeep(service);
  if (clonedService.subtasks) {
    clonedService.subtasks = clonedService.subtasks.map(sub => {
      if (isClientRequest(sub)) {
        sub.due_date = sub.dates?.[0]?.date;
        delete sub.dates;
        sub.message = sub.description;
        delete sub.description;
        sub.reminder_data = sub.request_tools[0].reminder_data;
      }
      return sub;
    });
  }
  return clonedService;
}

export function recurrenceHasError(service, hasRecurrenceLimitError) {
  return !!service.recurrence?.interval_unit && (hasRecurrenceLimitError || !service.recurrence?.interval);
}

function clearBadRolesFromReminders(service, fullRoleOptions) {
  return service.reminders.map(reminder => {
    reminder.relationships.notification_recipients = reminder.relationships.notification_recipients.filter(
      recipient => recipient.type !== 'role' || (fullRoleOptions && recipient.subName !== 'Unassigned')
    );
    return reminder;
  });
}

export function clearUnassignedRolesFromReminders(service, fullRoleOptions) {
  if (service.reminders?.length) {
    service.reminders = clearBadRolesFromReminders(service, fullRoleOptions);
  }
  if (service.subtasks?.length) {
    service.subtasks = service.subtasks.map(subtask => {
      if (subtask.reminders?.length) {
        subtask.reminders = clearBadRolesFromReminders(subtask, fullRoleOptions);
      }
      return subtask;
    });
  }
  return service;
}

export function checkReminderForUnassignedRoleError(reminder, fullRoleOptions) {
  const unassignedRolesLength = reminder.relationships.notification_recipients?.filter(recipient => {
    if (recipient.type === 'role') {
      const recipientToCheck =
        !!recipient.subName || !!recipient.teams || !!recipient.users
          ? recipient
          : fullRoleOptions.find(role => role.id === recipient.id) || {};
      return recipientToCheck.subName
        ? recipientToCheck.subName === 'Unassigned'
        : !recipientToCheck.teams?.length && !recipientToCheck.users?.length;
    }
    return false;
  })?.length;
  return unassignedRolesLength > 0 && unassignedRolesLength === reminder.relationships.notification_recipients?.length;
}

export function checkAllRemindersForUnassignedRoleErrors(service, fullRoleOptions) {
  const serviceRemindersHaveError = !!service.reminders?.find(reminder =>
    checkReminderForUnassignedRoleError(reminder, fullRoleOptions)
  );
  const serviceSubtaskRemindersHaveError = !!service.subtasks?.find(
    subtask => !!subtask.reminders?.find(reminder => checkReminderForUnassignedRoleError(reminder, fullRoleOptions))
  );
  return serviceRemindersHaveError || serviceSubtaskRemindersHaveError;
}
