// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-priority-styles__selectPriority--OWFtN {
  height: 28px;
  background-color: var(--cp-color-well-l1-bg);
  border: 1px solid var(--cp-color-well-border);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 8px;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/common/select-priority/select-priority.styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,4CAA4C;EAC5C,6CAA6C;EAC7C,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".selectPriority {\n  height: 28px;\n  background-color: var(--cp-color-well-l1-bg);\n  border: 1px solid var(--cp-color-well-border);\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  padding: 0 12px 0 8px;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectPriority": `select-priority-styles__selectPriority--OWFtN`
};
export default ___CSS_LOADER_EXPORT___;
