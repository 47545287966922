import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
  useMutation,
  queryClient,
  queryOptions,
  setupQueryKeygen,
} from 'fetcher!sofe';
export { useQueryClient, useQuery, useInfiniteQuery, useMutation, queryClient, queryOptions };
export const { genQueryKey, genQueries, invalidateEntity } = setupQueryKeygen('tasks-ui');
