import React from 'react';
import styles from './status-dropdown.styles.css';
import { CpButton, CpColorPicker, CpInput } from 'canopy-styleguide!sofe';

export default class StatusCreation extends React.Component {
  state = {
    name: '',
    color: '#FF0000',
  };
  render() {
    return (
      <div
        onClick={e => {
          e.stopPropagation();
        }}
        className={`${styles.createStatus} cps-card`}
      >
        <div className={`${styles.createStatusBody}`}>
          <CpInput
            placeholder="Status name"
            value={this.state.name}
            onChange={this.updateName}
            className={styles.createStatusInput}
            ref={ref => ref?.focus()}
          />
          <CpColorPicker onColorChange={this.updateColor} color={this.state.color} />
        </div>
        <div className={`${styles.createStatusFooter} cps-bg-gray-1`}>
          <CpButton disabled={!this.state.name || !this.state.color} onClick={this.createStatus} btnType="primary">
            Done
          </CpButton>
          <CpButton onClick={this.props.close} btnType="flat">
            Cancel
          </CpButton>
        </div>
      </div>
    );
  }
  updateName = e => {
    if (e.length <= 30) {
      this.setState({
        name: e,
      });
    }
  };
  updateColor = color => {
    this.setState({
      color,
    });
  };
  createStatus = () => {
    this.props.close();
    this.props.createStatus({
      color: this.state.color,
      name: this.state.name,
    });
  };
}
