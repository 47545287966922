import { of, throwError } from 'rxjs';
import { tap, pluck, catchError, map } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { fetchAsObservable } from 'fetcher!sofe';
import { visibleColumnMigration, taskPropMigration, columnSettingsMigration } from 'src/dashboard/dashboard.helpers';
import { handleError } from 'src/common/error.helper';
import { genQueries, queryOptions } from 'src/react-query';

export const maxPageSize = 100;

export function getTasks(params, omitChildSubtasks = false) {
  const {
    pageNum = 0,
    filterAndSortObj = {},
    visibleColumns = [],
    includeCompleted = false,
    snapshotId,
    clientId,
    pageSize = maxPageSize,
    type,
    includeTime = true,
    showSubtasks = false,
  } = params;

  const body = {
    filters: filterAndSortObj,
    visible_columns: visibleColumnMigration(visibleColumns),
  };

  let payload = {
    method: !snapshotId ? 'POST' : 'GET',
  };

  if (!snapshotId) {
    payload.body = body;
  }

  const base = `/api/task_dashboard`;
  const pageNumAndLimit = `page=${pageNum}&limit=${pageSize}`;
  const snapshotIdAndCompleted = `${snapshotId ? `&snapshot=${snapshotId}` : ''}${
    includeCompleted ? '&include=completed' : ''
  }`;
  const clientIdUrl = `${clientId ? `&client_id=${clientId}` : ''}`;
  const filterByType = `${type ? `&type=${type}` : ''}`;
  const include_time = `${includeTime ? `&include_time=true` : ''}`;
  const include_subtasks = `&include_subtasks=${showSubtasks}`;
  const subtaskToggle = `&subtask_toggle=${omitChildSubtasks}`;
  const include_pin = `${clientId ? '&include_pin=false' : ''}`;

  const queryParams = `?${pageNumAndLimit}${snapshotIdAndCompleted}${clientIdUrl}${filterByType}${include_time}${include_subtasks}${include_pin}${subtaskToggle}`;

  return fetchAsObservable(`${base}${queryParams}`, payload).pipe(map(taskPropMigration));
}

export function getArchivedTasks(name_filter) {
  return fetchAsObservable(`/api/tasks:archive${!isEmpty(name_filter) ? `?name_filter=${name_filter}` : ''}`);
}

export function postTask(task) {
  return fetchAsObservable(`/api/tasks`, {
    method: 'POST',
    body: { tasks: task },
  }).pipe(
    tap(resp => {
      window.dispatchEvent(new CustomEvent('cp:task-created', { detail: (resp && resp.tasks) || task }));
    })
  );
}

export function patchTask({ ids, task, toInclude = [], import_id }) {
  if (typeof ids === 'string') {
    ids = ids.split(',');
  }
  task.name = task.name?.trim();
  task.subtasks = task.subtasks?.map(subtask => ({ ...subtask, name: subtask.name.trim() }));
  return fetchAsObservable(`/api/tasks/?include=${toInclude.join(',')}`, {
    method: 'PATCH',
    body: { task_ids: ids, tasks: task, import_id },
  });
}

export function patchTaskDate(dateId, body) {
  return fetchAsObservable(`/api/task-dates/${dateId}`, {
    method: 'PATCH',
    body,
  });
}

export function getTask(id, includes = []) {
  return fetchAsObservable(`/api/tasks/${id}?include=files,${includes.join(',')}`).pipe(
    pluck('tasks'),
    catchError(err => {
      if ([403, 404].includes(err.status) || err.toString().toLowerCase().indexOf('failed to fetch') !== -1) {
        window.dispatchEvent(new CustomEvent('taskunavailable'));
        return of([]);
      } else {
        return throwError();
      }
    })
  );
}

export function deleteTask(id) {
  return fetchAsObservable(`/api/tasks/${id}`, {
    method: 'DELETE',
  });
}

export function archiveTasks(ids, force, uuid) {
  return newTasksBulkAction({ ids, action: 'archive', force, uuid });
}

export function unarchiveTasks(ids) {
  return newTasksBulkAction({ ids, action: 'unarchive' });
}

export function bulkAssign(taskIds, userIds) {
  return tasksBulkAction(taskIds, 'assign', { assigned_to: userIds });
}

export function tasksBulkAction(ids, action, body = {}) {
  return fetchAsObservable(`/api/tasks/${ids}:${action}`, {
    method: 'POST',
    body,
  });
}

export function newTasksBulkAction({ ids, action, force, uuid }) {
  return fetchAsObservable(`/api/tasks:${action}`, {
    method: 'POST',
    body: { force, task_ids: ids, import_id: uuid },
  });
}

export function setPinStatus(taskID, pinned) {
  let body = `{
    "pinned": ${pinned}
  }`;
  return fetchAsObservable(`/api/tasks/${taskID}/pin`, {
    method: 'PATCH',
    body,
  });
}

export function getColumnFilterOptions(columnKey, includeCompletedStatus = false, snapshot_id = null) {
  return fetchAsObservable(
    `/api/task_dashboard/get_filter_options?filter_type=${columnKey}${
      includeCompletedStatus ? '&include=completed' : ''
    }${snapshot_id ? '&snapshot_id=' + snapshot_id : ''}`
  ).pipe(pluck('options'));
}

export function deleteRelationship(recordType, recordId, relationships) {
  return fetchAsObservable(`/api/relationships/${recordType}/${recordId}`, {
    method: 'DELETE',
    body: { relationships },
  });
}

export function completeTask(taskId, complete) {
  return fetchAsObservable(`/api/tasks/${taskId}/complete`, {
    method: 'PATCH',
    body: { complete },
  });
}

export function getTaskDashboardFilterGroups() {
  return fetchAsObservable(`/api/filtergroups/?type=tasks`).pipe(
    map(response => ({
      ...response,
      filter_groups: response.filter_groups.map(filterGroup => ({
        ...filterGroup,
        data: {
          ...filterGroup.data,
          jql: {
            ...filterGroup.data.jql,
            columnSettings: columnSettingsMigration(filterGroup.data.jql.columnSettings),
            visibleColumns: visibleColumnMigration(filterGroup.data.jql.visibleColumns),
          },
        },
      })),
    }))
  );
}

export function addFilterGroup(payload) {
  return fetchAsObservable(`/api/filtergroups`, {
    method: 'POST',
    body: payload,
  });
}

export function patchFilterGroup(id, preferences, name) {
  let filterGroupsObj = {
    filter_groups: {
      data: { jql: preferences },
    },
  };
  if (name) filterGroupsObj.filter_groups.name = name.trim();
  return fetchAsObservable(`/api/filtergroups/${id}`, {
    method: 'PATCH',
    body: filterGroupsObj,
  });
}

export function patchFilterGroups(filterGroups) {
  return fetchAsObservable(`/api/filtergroups`, {
    method: 'PUT',
    body: { filter_groups: filterGroups },
  }).pipe(pluck('filter_groups'));
}

export function getExportCSV(showSubtasks, filters, visible_columns, completed) {
  let includeCompleted = completed ? '&include=completed' : '';
  return fetchAsObservable(`/api/task_dashboard?export=true&include_subtasks=${showSubtasks}${includeCompleted}`, {
    method: 'POST',
    body: {
      filters,
      visible_columns,
    },
  });
}

export function getClientUsers(client_id) {
  return fetchAsObservable(`/api/clients/${client_id}/users`).pipe(pluck('users'));
}

export function getActiveFilters() {
  return fetchAsObservable(`/api/active-filters`).pipe(
    pluck('active_filters'),
    map(response => ({
      ...response,
      visibleColumns: visibleColumnMigration(response.visibleColumns),
    }))
  );
}

export function updateActiveFilters(body) {
  return fetchAsObservable(`/api/active-filters`, {
    method: 'PUT',
    body: {
      active_filters: {
        ...body,
        visibleColumns: visibleColumnMigration(body.visibleColumns),
      },
    },
  });
}

export function getTaskEmail(emailSnapshotId) {
  return fetchAsObservable(`/wg/email_snapshots/${emailSnapshotId}`);
}

export function patchEmailSnapshotId(taskId, emailSnapshotId) {
  return fetchAsObservable(`/api/tasks/${taskId}`, {
    method: 'PATCH',
    body: {
      tasks: {
        email_snapshot_id: isEmpty(emailSnapshotId) ? null : emailSnapshotId,
      },
    },
  });
}

export function getTaskNameVariables(isClientRequest) {
  return fetchAsObservable(`/api/task_variable_names${isClientRequest ? '?type=client_request' : ''}`).pipe(
    pluck('task_name_variables')
  );
}

export const variableQueries = genQueries('name-variables', ({ genKey }) => ({
  taskNameVariables: (enabled = true) =>
    queryOptions({
      queryKey: genKey('taskNameVariables'),
      queryFn: () => getTaskNameVariables(false).toPromise(),
      staleTime: Infinity,
      enabled,
    }),
  clientRequestNameVariables: (hasClientRequests = true) =>
    queryOptions({
      queryKey: genKey('clientRequestNameVariables'),
      queryFn: () => getTaskNameVariables(true).toPromise(),
      staleTime: Infinity,
      enabled: hasClientRequests,
    }),
}));

export function setRecurringTaskName(taskId, name) {
  return fetchAsObservable(`/api/tasks/${taskId}/recur_dynamic_name`, {
    method: 'PATCH',
    body: {
      name,
    },
  });
}

export function getSubtasksForTasks(taskIds, visibleColumns) {
  return fetchAsObservable(
    `/api/tasks/subtasks?include_tools=true`,
    {
      method: 'POST',
      body: {
        task_ids: taskIds,
        visible_columns: visibleColumns,
      },
    },
    handleError
  ).pipe(pluck('tasks'));
}
