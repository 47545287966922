import React from 'react';
import { CpArea, CpDropdown, CpIcon, CpTooltip } from 'canopy-styleguide!sofe';
import { getPriorityColor, priorityOptions } from './select-priority.helper';

import styles from './select-priority.styles.css';

export const SelectPriorityDropdown = React.memo(({ value, onChange, disabled }) => {
  value = value?.name ?? value;
  return (
    <CpDropdown
      disabled={disabled}
      renderTrigger={({ toggle }) => (
        <CpArea style={{ borderRadius: '8px' }}>
          <CpTooltip text={value}>
            <div className={styles.selectPriority} onClick={toggle}>
              <CpIcon className="cp-mr-4" name="urgent-open-small" fill={getPriorityColor(value)} />
              <div className={`cp-ellipsis ${disabled ? 'cp-color-app-disabled-text' : ''}`}>{value}</div>
            </div>
          </CpTooltip>
        </CpArea>
      )}
      renderContent={() => (
        <div className="cp-select-list">
          {priorityOptions.map(option => (
            <button key={option.name} onClick={() => onChange(option.name)}>
              <CpIcon className="cp-mr-4" name="urgent-open-small" fill={getPriorityColor(option.name)} />
              {option.name}
            </button>
          ))}
        </div>
      )}
    />
  );
});
