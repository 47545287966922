import { userHasOnlyLicense } from 'cp-client-auth!sofe';

export function renameTasksToNotices(str, shouldRename) {
  if (!shouldRename) return str;
  const [replace] = str.match(/task(s?)/i) || [];
  if (!replace) return str;

  // is uppercase
  const firstLetter = replace.slice(0, 1);
  const isUpperCase = firstLetter.toUpperCase() === firstLetter;

  // is plural
  const isPlural = replace.slice(replace.length - 1) === 's';

  // replace
  const notice = `${isUpperCase ? 'N' : 'n'}otice${isPlural ? 's' : ''}`;
  return str.replace(replace, notice);
}

export function isTranscriptsOnly(user) {
  return userHasOnlyLicense(user, 'transcripts');
}
