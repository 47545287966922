import { isEqual } from 'lodash';

export const getClearedFilters = (dashboardPrefs, include_completed_tasks = false) => {
  if (!dashboardPrefs?.columnSettings) return {};
  const clearedFilters = clearFilters(
    Object.keys(dashboardPrefs.columnSettings),
    dashboardPrefs.columnSettings['date.calculated_date']
  );
  return {
    columnSettings: clearedFilters,
    filter_group_id: '',
    include_completed_tasks,
  };
};

export const clearFilters = (columnSettingsArr, dates) => {
  return columnSettingsArr.reduce((acc, cur) => {
    if (cur !== 'date.calculated_date') {
      acc[cur] = { order: null, filter_params: null };
    } else {
      acc[cur] = dates
        ? dates.map(dateType => {
            return { date_type_id: dateType.date_type_id, date_group: null, filter_params: null, order: null };
          })
        : [];
    }
    return acc;
  }, {});
};

export const checkFiltersChanged = (preferences, filter) => {
  return (
    !preferences ||
    !filter ||
    (filter.visibleColumns && !isEqual(preferences.visibleColumns, filter.visibleColumns)) ||
    preferences.include_completed_tasks !== filter.include_completed_tasks ||
    checkColumnSettingsChanged(preferences.columnSettings, filter.columnSettings)
  );
};

const checkColumnSettingsChanged = (preferencesCS, filterCS) => {
  return Object.keys(preferencesCS).some(key => {
    if (
      !preferencesCS[key].order &&
      !preferencesCS[key].filter_params && // both are empty
      !filterCS[key] // but it doesn't even exist in our filter so in reality there is no change
    )
      return;

    if ((preferencesCS[key].order || preferencesCS[key].filter_params) && !filterCS[key]) return true;

    return (
      preferencesCS[key].order !== filterCS[key].order ||
      !isEqual(preferencesCS[key].filter_params, filterCS[key].filter_params)
    );
  });
};

export const getNumberOfFilterChanges = (preferences, filter) => {
  if (!preferences || !filter || !Object.keys(preferences).length || !Object.keys(filter).length) return;
  let count = checkVisibleColumnsReordered(preferences.visibleColumns, filter.visibleColumns);
  if (preferences.include_completed_tasks !== filter.include_completed_tasks) {
    count++;
  }
  Object.keys(preferences.columnSettings).map(key => {
    let preferencesSettings = preferences.columnSettings[key];
    let filterSettings = filter.columnSettings?.[key];
    if (filterSettings || key === 'date.calculated_date') {
      if (key !== 'date.calculated_date') {
        count = findDifferences(count, preferencesSettings, filterSettings);
      } else {
        preferencesSettings.map(date => {
          let matchingDate = filterSettings?.find(filterDate => filterDate.date_type_id === date.date_type_id);
          if (matchingDate) {
            count = findDifferences(count, date, matchingDate);
          } else {
            if (date.order) count++;
            if (date.filter_params) count++;
            if (date.width) count++;
          }
        });
      }
    } else {
      if (preferencesSettings.order) count++;
      if (preferencesSettings.filter_params) count++;
      if (preferencesSettings.width) count++;
    }
  });
  return count;
};

export const findDifferences = (count, prefCSWithKey, filterCSWithKey) => {
  if ((!!prefCSWithKey.order || !!filterCSWithKey.order) && prefCSWithKey.order !== filterCSWithKey.order) {
    count++;
  }
  if (!isEqual(prefCSWithKey.filter_params, filterCSWithKey.filter_params)) count++;
  if (
    prefCSWithKey.width !== filterCSWithKey.width &&
    (prefCSWithKey.width !== 0 || filterCSWithKey.width !== undefined)
  ) {
    count++;
  }
  return count;
};

export const checkVisibleColumnsReordered = (preferencesVC = [], filterVC = []) => {
  let changes = 0;
  if (filterVC.length && preferencesVC.length) {
    preferencesVC.find((col, i) => {
      if (col !== filterVC[i]) {
        changes = 1;
        return true;
      }
    });
  } else if (filterVC.length !== preferencesVC.length) {
    return 1;
  }
  return changes;
};
