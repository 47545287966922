export const keyValues = Object.freeze({
  DRAFT: 'DRAFT',
  WITH_CLIENT: 'WITH_CLIENT',
  REVIEW: 'REVIEW',
  COMPLETED: 'COMPLETED',
});

export const statusOptions = [
  {
    value: 'DRAFT',
    id: 'DRAFT',
    name: 'Draft',
    color: '#CCBDAD',
  },
  {
    value: 'WITH_CLIENT',
    id: 'WITH_CLIENT',
    name: 'With client',
    color: '#8642ff',
  },
  {
    value: 'REVIEW',
    id: 'REVIEW',
    name: 'Needs review',
    color: '#ff345e',
  },
  {
    value: 'COMPLETED',
    id: 'COMPLETED',
    name: 'Completed',
    color: '#1cbd51',
  },
];

export const clientRequestStatuses = {
  NOSTATUS: {
    value: 'NOSTATUS',
    id: 'NOSTATUS',
    label: 'Draft',
    color: '#CCBDAD',
  },
  DRAFT: {
    value: 'DRAFT',
    id: 'DRAFT',
    label: 'Draft',
    color: '#CCBDAD',
  },
  WITH_CLIENT: {
    value: 'WITH_CLIENT',
    id: 'WITH_CLIENT',
    label: 'With client',
    color: '#8642ff',
  },
  REVIEW: {
    value: 'REVIEW',
    id: 'REVIEW',
    label: 'Needs review',
    color: '#ff345e',
  },
  COMPLETED: {
    value: 'COMPLETED',
    id: 'COMPLETED',
    label: 'Completed',
    color: '#1cbd51',
  },
};
