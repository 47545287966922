import { fetchAsObservable } from 'fetcher!sofe';
import { throwError } from 'rxjs';
import { pluck } from 'rxjs/operators';

export function createClientList(data) {
  return fetchAsObservable(`/api/client_lists`, {
    method: 'POST',
    // todo remove {jql: data } with ft_crm toggle
    body: {data: {...data?.filters ? {...data}  : { jql: data } }},
  });
}

export function addClientToList(snapshotId, clientId) {
  if (!snapshotId) return throwError(`Cannot add client from list without a snapshotId`);
  if (!clientId) return throwError(`Cannot add client from list without a clientId`);

  return fetchAsObservable(`/api/client_lists/${snapshotId}`, {
    method: 'PATCH',
    body: {
      data: {
        client_id: clientId,
        exclude: false,
      },
    },
  });
}

//New endpoint that sends dictionary of clients organized by team member id. Used for resolve modal
export function getUserClientsAccess(snapshot_id, userIds) {
  if (!snapshot_id) return throwError(`Cannot check permissions without a snapshot_id`);
  if (!userIds) return throwError(`Cannot check permissions without a userIds`);

  return fetchAsObservable(`/api/client_lists/${snapshot_id}/users-nonassigned-clients?user_id=${userIds.join(',')}`);
}

export function removeClient(snapshot_id, client_id) {
  return fetchAsObservable(`/api/client_lists/${snapshot_id}`, {
    method: 'PATCH',
    body: {
      data: { client_id, exclude: true },
    },
  }).toPromise();
}

export function getClientsFromSnapshot(snapshot_id) {
  return fetchAsObservable(`/api/client_lists/${snapshot_id}`).pipe(pluck('client_ids')).toPromise();
}

export function addClientsToAssignee(assigneeId, clientIds) {
  return fetchAsObservable(`/api/users/${assigneeId}/clients`, {
    method: 'PATCH',
    body: { new_clients: clientIds },
  }).toPromise();
}

export function createClientListWithoutPortals(jql) {
  return fetchAsObservable(`/api/client_lists:split_by_portals`, {
    method: 'POST',
    body: { data: { jql } },
  });
}

export function sendClientRequestsBulk(taskIds) {
  return fetchAsObservable(`/api/tasks:send_client_requests`, {
    method: 'PATCH',
    body: {
      task_ids: taskIds,
    },
  }).toPromise();
}

export function getClientsWithoutEmailsFromSnapshot(snapshot_id) {
  return fetchAsObservable(`/api/client_lists/${snapshot_id}/users_without_emails`).pipe(pluck('clients'));
}

export function checkForCreationLimit(recurrence, due_date) {
  return fetchAsObservable(`/api/task_creation_limit_check`, {
    method: 'POST',
    body: {
      recurrence_data: recurrence,
      due_date,
    },
  });
}
