import { fetchAsObservable } from 'fetcher!sofe';
import { pluck } from 'rxjs/operators';

export function getEsignDoc(docId) {
  // this gets an esign docs for a document from canopy files
  return fetchAsObservable(`/api/docs/esign-docs/${docId}`).pipe(pluck('esign_doc'));
}

export function getEditableEsignDoc(docId) {
  // this gets and esign doc for live accusoft docs
  return fetchAsObservable(`/wg/documents/${docId}/prepare_esign`, {
    method: 'POST',
  }).pipe(pluck('file_id'));
}

export function resendEsignRequest(taskId, signer) {
  // this resends the esign task notification to a user/signer
  return fetchAsObservable(`/api/tasks/${taskId}/resend`, {
    method: 'POST',
    body: {
      user_id: signer.signatory_user_id,
      signer_id: signer.signer_id,
    },
  });
}
